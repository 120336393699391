import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Link, Paper, Grid, Button } from '@material-ui/core';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Freepaper = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetch('https://macplam.com/config/contact.json')
      .then((res) => res.json())
      .then((data) => setContacts(data.contacts)) // ✅ Akses properti `contacts` dari objek
      .catch((error) => console.error('Error fetching contacts:', error)); // ✅ Tambahkan error handling

    AOS.init({ duration: 2000, once: true });
  }, []);

  return (
    <Container maxWidth="lg" style={{ paddingTop: 100, minHeight: '100vh' }}>
      {/* Judul */}
      <Typography 
        variant="h4" 
        gutterBottom 
        align="center" 
        data-aos="fade-down"
        style={{ fontWeight: 'bold', marginBottom: 30 }}
      >
        Informasi Kontak
      </Typography>

      {/* Konten Grid */}
      <Grid container spacing={4} justifyContent="center">
        {contacts.map((item, idx) => (
          <Grid item xs={12} sm={6} md={4} key={idx} data-aos="fade-up" data-aos-delay={idx * 200}>
            <Paper 
              elevation={4} 
              style={{
                padding: 25, 
                textAlign: 'center', 
                borderRadius: 12, 
                height: '100%',
              }}
            >
              {/* Judul Section */}
              <Typography 
                variant="h6" 
                gutterBottom 
                style={{ fontWeight: 'bold', marginBottom: 15 }}
              >
                {item.title}
              </Typography>

              {/* Tombol-tombol */}
              {item.contacts.map((contact, index) => (
                <Box key={index} mb={2} display="flex" justifyContent="center" style={{margin: 20}}>
                  <Link href={contact.link} target="_blank" rel="noopener noreferrer">
                    <Button 
                      variant="contained" 
                      color="primary"
                      style={{
                        width: '100%',
                        fontSize: '14px',
                        textTransform: 'none',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                        borderRadius: '8px'
                      }}
                    >
                      {contact.name}
                    </Button>
                  </Link>
                </Box>
              ))}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Freepaper;