import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Link, Grid, Paper } from '@material-ui/core';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Freepaper = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('https://macplam.com/config/freepaper.json')
      .then((res) => res.json())
      .then((json) => setData(json));

    AOS.init({ duration: 2000, once: true });
  }, []);

  if (!data) return null;

  const renderStyledText = (text) => {
    const parts = text.split(/\*\*(.*?)\*\*/g);
    return parts.map((part, index) => 
      index % 2 === 1 ? 
      <strong key={index}>{part}</strong> : 
      part
    );
  };
  
  return (
    <Container maxWidth="md" style={{ paddingTop: 100, paddingBottom: 100 }}>
      {/* Judul Besar */}
      <Typography 
        variant="h4" 
        align="center" 
        gutterBottom 
        data-aos="fade-down" 
        style={{ fontWeight: 'bold', marginBottom: 30 }}
      >
        {data.title}
      </Typography>

      {/* Section Berisi Pedoman */}
      {data.sections.map((section, index) => (
        <Box key={index} mb={5} data-aos="fade-up" data-aos-delay={index * 200}>
          <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: 10 }}>
            {section.title}
          </Typography>
          <ol>
            {section.content.map((item, i) => (
              <li key={i} style={{ marginBottom: 8 }}>{renderStyledText(item)}</li>
            ))}
          </ol>
        </Box>
      ))}

      {/* Bagian Download */}
      <Box mt={4}>
        <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: 10 }}>
          Download Template:
        </Typography>

        {data.downloads.map((category, index) => (
          <Paper 
            key={index} 
            elevation={3} 
            style={{ padding: 20, marginBottom: 20 }} 
            data-aos="fade-up"
          >
            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: 10 }}>
              {category.category}
            </Typography>
            <Grid container spacing={2}>
              {category.files.map((file, idx) => (
                <Grid item xs={12} sm={6} key={idx}>
                  <Link 
                    href={file.link} 
                    download 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{
                      fontWeight: 'bold', 
                      fontSize: 16, 
                      color: '#0073e6',
                      display: 'block'
                    }}
                  >
                    📥 {file.name}
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Paper>
        ))}
      </Box>
    </Container>
  );
};

export default Freepaper;
