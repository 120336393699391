import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Container } from '@mui/material';
import { motion } from 'framer-motion';

const JadwalSection = () => {
  const [jadwal, setJadwal] = useState([]);
  const [selectedDay, setSelectedDay] = useState(0);

  useEffect(() => {
    fetch('https://macplam.com/config/jadwal.json')
      .then((res) => res.json())
      .then((data) => setJadwal(data.jadwal));
  }, []);

  return (
    <Container sx={{ py: 8, paddingTop: 20 }}>
      <Box display="flex" justifyContent="center" mb={3}>
        {jadwal.map((item, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={() => setSelectedDay(index)}
            sx={{
              backgroundColor:
                index === selectedDay
                  ? ['#6a49f5', '#ff7700', '#00b7ff', '#74b816'][index % 4]
                  : 'grey.300',
              color: index === selectedDay ? '#fff' : '#555',
              mx: 1,
              paddingLeft: 10, paddingRight: 10,
              '&:hover': {
                opacity: 0.9,
              },
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {item.hari}
              </Typography>
              <Typography variant="h8">{item.tanggal}</Typography>
            </Box>
          </Button>
        ))}
      </Box>

      {jadwal[selectedDay] && (
        <motion.div
          key={selectedDay}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <Box
            component="img"
            src={jadwal[selectedDay].image}
            alt={`Jadwal ${jadwal[selectedDay].hari}`}
            sx={{
              width: '100%',
              borderRadius: 2,
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}
          />
        </motion.div>
      )}
    </Container>
  );
};

export default JadwalSection;
