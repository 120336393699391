import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';

const PaketSection = () => {
  return (
    <Container sx={{ py: 10 }}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: 'center',
            color: 'gray',
            mb: 1,
            letterSpacing: 3,
            fontWeight: 'medium',
          }}
        >
          --LIST HARGA MaCPLAM 2025--
        </Typography>

        <Box sx={{ textAlign: 'center', width: '100%' }}>

        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            mb: 4,
            position: 'relative',
            display: 'inline-block',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 5,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              height: '10px',
              backgroundColor: '#FFC107',
              zIndex: -1,
            },
          }}
        >
          Paket Program Event
        </Typography>
        </Box>

        <Box
          component="img"
          src="https://macplam.com/assets/harga.jpg" // URL gambar dari hosting
          alt="Paket Harga Event"
          sx={{
            width: '100%',
            borderRadius: 2,
            boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
            mt: 4,
          }}
        />
      </motion.div>
    </Container>
  );
};

export default PaketSection;