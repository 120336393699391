import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Card, CardMedia, CardContent, Box } from '@mui/material';
import { motion } from 'framer-motion';

const WisataSection = () => {
  const [wisataData, setWisataData] = useState([]);

  useEffect(() => {
    fetch('https://macplam.com/config/wisata.json')
      .then((response) => response.json())
      .then((data) => setWisataData(data.wisata))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <Box sx={{ py: 8, background: '#054261' }}>
    
    <Container>
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            mb: 4,
            display: 'inline-block',
            pb: 1,
            color: 'white'
          }}
        >
          Destinasi Wisata
        </Typography>
      </Box>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        {wisataData.map((wisata, index) => (
          <Grid item xs={12} md={6} key={index}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.3 }}
              viewport={{ once: true }}
            >
              <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image={`https://macplam.com/assets/wisata${index + 1}.jpeg`}
                  alt={wisata.title}
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
                    {wisata.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {wisata.description}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
    </Box>
  );
};

export default WisataSection;
