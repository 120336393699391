
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import bg from '../assets/vbbg.jpg';

export default function BoothSelection() {
  const [selectedBooth, setSelectedBooth] = useState(null);
  const [booths, setBooths] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const boothsPerPage = 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://quinnstechnology.com/macplam/webservice.php?fungsi=listbooth');
        setBooths(response.data);
      } catch (error) {
        window.alert(error);
      }
    };
    fetchData();
  }, []);

  const handleBoothClick = (booth) => {
    if (selectedBooth === booth) {
      setSelectedBooth(null);
    } else {
      setSelectedBooth(booth);
      setTimeout(() => {
        navigate('/booth/' + booth.id);
      }, 2000);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(booths.length / boothsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * boothsPerPage;
  const endIndex = startIndex + boothsPerPage;

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      background: `url(${bg}) no-repeat center center fixed`,
      backgroundSize: 'cover'
    }}>
      {booths.slice(startIndex, endIndex).map(booth => (
        <div
          key={booth.id}
          style={{
            margin: 20,
            flex: 1,
            transition: 'transform 5s, opacity 0.5s',
            transform: selectedBooth === booth ? 'scale(2)' : 'scale(1)',
            opacity: selectedBooth && selectedBooth !== booth ? 0 : 1
          }}
        >
          <img
            src={"https://quinnstechnology.com/macplam/booth/"+booth.booth}
            alt={`Booth ${booth.id}`}
            style={{ width: '100%', height: '80vh', cursor: 'pointer', objectFit: 'contain' }}
            onClick={() => handleBoothClick(booth)}
          />
        </div>
      ))}

      <div style={{ position: 'absolute', left: '10px', top: '95%', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: 100 }} onClick={handlePrevPage}>
        <img src={require('../assets/back.png')} alt="Previous" style={{ opacity: 0.8, height: 70, width: 70 }} />
      </div>

      <div style={{ position: 'absolute', right: '10px', top: '95%', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: 100 }} onClick={handleNextPage}>
        <img src={require('../assets/fw.png')} alt="Next" style={{ opacity: 0.8, height: 70, width: 70 }} />
      </div>
    </div>
  );
}
