import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';

import Dropdown from '../components/Dropdown';
import '../components/style.css';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    flexDirection: 'row'
  },
  form: {
    backgroundColor: 'white',
    padding: theme.spacing(5),
    borderRadius: '5px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1'
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '36px',
    marginBottom: theme.spacing(4)
  },
  input: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(3),
    backgroundColor: '#008CBA',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0077A5'
    }
  },
  priceImage: {
    width: '100%',
    display: 'block',
    margin: 'auto'
  }
}));

function Registrasi(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  // State untuk paket & diskon
  const [paketData, setPaketData] = useState([]);
  const [discountData, setDiscountData] = useState([]);

  // State untuk pilihan user
  const [selectedPakets, setSelectedPakets] = useState([]);
  const [profesi, setProfesi] = useState('Dokter Spesialis');

  // State form input
  const [fullName, setFullName] = useState('');
  const [institution, setInstitution] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nik, setNik] = useState(''); // ➜ Tambahkan NIK
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');

  // Total yang akan ditampilkan ke user
  const [total, setTotal] = useState(0);

  // Lainnya
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [loading, setLoading] = useState(false);

  const now = new Date();

  // 1. Fetch data paket
  useEffect(() => {
    axios
      .get('https://macplam.com/api/getHarga.php?fungsi=paket')
      .then((response) => {
        setPaketData(response.data);
      })
      .catch((error) => {
        console.log('Error fetching paket data:', error);
      });
  }, []);

  // 2. Fetch data diskon
  useEffect(() => {
    axios
      .get('https://macplam.com/api/getHarga.php?fungsi=diskon')
      .then((response) => {
        setDiscountData(response.data);
      })
      .catch((error) => {
        console.log('Error fetching discount data:', error);
      });
  }, []);

  // 3. Resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 4. Inisialisasi AOS
  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  // Validasi email
  const isValidEmail = (em) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(em);
  };

  // Cek/uncek paket
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedPakets((prev) => [...prev, value]);
    } else {
      setSelectedPakets((prev) => prev.filter((paket) => paket !== value));
    }
  };

  // Filter data paket sesuai profesi
  const filteredData = paketData.filter((item) => item.profesi === profesi);

  // Daftar kategori unik
  const uniqueCategories = Array.from(new Set(filteredData.map((item) => item.kategori)));

  // Helper: cek apakah dua array punya elemen sama persis (dilihat sebagai set)
  const arraysEqualAsSets = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    if (set1.size !== set2.size) return false;
    for (const elem of set1) {
      if (!set2.has(elem)) return false;
    }
    return true;
  };

  // 5. Hitung total (termasuk diskon) setiap kali selectedPakets/filteredData berubah
  useEffect(() => {
    let newTotal = 0;

    // 5.1 Hitung total normal dari setiap kategori yang dipilih
    selectedPakets.forEach((kategoriTerpilih) => {
      const matchedItem = filteredData.find((item) => item.kategori === kategoriTerpilih);
      if (matchedItem) {
        const earlyBirdEnd = new Date(matchedItem.early_bird_end);
        if (now < earlyBirdEnd) {
          newTotal += parseInt(matchedItem.harga_early, 10);
        } else {
          newTotal += parseInt(matchedItem.harga_normal, 10);
        }
      }
    });

    // 5.2 Cek apakah ada diskon (kombinasi) yang cocok dengan set paket terpilih
    discountData.forEach((disc) => {
      if (disc.profesi === profesi) {
        // "Workshop 1 + Simposium" => ["Workshop 1", "Simposium"]
        const splittedCombo = disc.kombinasi.split(' + ').map((item) => item.trim());
        // Kalau setnya sama
        if (arraysEqualAsSets(splittedCombo, selectedPakets)) {
          const discEarlyBirdEnd = new Date(disc.early_bird_end);
          if (now < discEarlyBirdEnd) {
            // Gunakan harga_diskon (early bird)
            newTotal = parseInt(disc.harga_diskon, 10);
          } else {
            // Gunakan harga_normal (non-early-bird) -> kolom baru
            newTotal = parseInt(disc.harga_normal, 10);
          }
        }
      }
    });

    setTotal(newTotal);
  }, [selectedPakets, filteredData, discountData, now]);

  // 6. Submit form
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return;

    if (password !== repassword) {
      alert('Password tidak sama');
      return;
    }
    if (!isValidEmail(email)) {
      alert('Email tidak valid');
      return;
    }
    if (!nik.trim()) {
      alert('NIK harus diisi');
      return;
    }
    if (selectedPakets.length === 0) {
      alert('Silahkan pilih paket acara yang ingin diikuti.');
      return;
    }
    if (
      selectedPakets.includes('Free Paper') &&
      !selectedPakets.includes('Simposium')
    ) {
      alert('Peserta Free Paper wajib mengambil paket Simposium');
      return;
    }

    setLoading(true);

    try {
      // Gabungkan kategori yang dipilih user
      const paketString = selectedPakets.join(', ');

      const response = await axios.get('https://quinnstechnology.com/macplam/webservice.php', {
        params: {
          fungsi: 'register',
          email,
          nama: fullName,
          profesi,
          asal: institution,
          no_hp: phoneNumber,
          nik,                     // ✓ Kirim NIK
          provinsi: province,
          kota: city,
          password,
          paket: paketString,
          total
        }
      });

      const data = response.data;
      if (Number.isInteger(data)) {
        props.setAkun({
          id: data,
          nama: fullName,
          profesi,
          asal: institution,
          no_hp: phoneNumber,
          nik,                     // ✓ Simpan NIK di state Akun
          provinsi: province,
          kota: city,
          email,
          password,
          paket: paketString
        });
        navigate('/profile');
      } else if (data === 'sudah ada') {
        alert('Email sudah terdaftar');
        navigate('/profile');
      } else {
        alert(`Registrasi gagal: ${data}`);
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div
        className={classes.heroContent}
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: isSmallScreen ? 'column' : 'row',
          paddingTop: 100
        }}
        data-aos="fade-right"
      >
        {/* Gambar Harga (jika ada) */}
        <div style={{ 
          width: '100%', 
          marginLeft: isSmallScreen ? 0 : 20,
          marginRight: isSmallScreen ? 0 : 20,
          marginBottom: 30, 
          textAlign: 'center',
          padding: isSmallScreen ? '0 16px' : 0,
          boxSizing: 'border-box'
        }}>
          <img
            src="https://macplam.com/assets/harga.jpg"
            alt="Harga Registrasi"
            style={{
              maxWidth: '100%',
              height: 'auto',
              width: isSmallScreen ? '100%' : 'auto',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
            }}
            data-aos="zoom-in"
          />
        </div>
        <Container maxWidth="md">
          <form onSubmit={handleSubmit} className={classes.form}>
            <h2 className={classes.heading}>Registration Form</h2>

            {/* Dropdown Profesi */}
            <Dropdown
              label="Profesi"
              item={["Dokter Spesialis", "Residen/Dokter Umum"]}
              value={profesi}
              onChange={(e) => {
                setProfesi(e.target.value);
                setSelectedPakets([]); // reset pilihan
              }}
            />

            {/* Daftar checkbox paket */}
            <Typography variant="h5" style={{ marginBottom: 8 }}>
              Pilih Paket Acara:
            </Typography>
            <Box style={{ marginBottom: 16, textAlign: 'left', width: '100%' }}>
              {uniqueCategories.map((kategori, index) => (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <label>
                    <input
                      type="checkbox"
                      value={kategori}
                      checked={selectedPakets.includes(kategori)}
                      onChange={handleCheckboxChange}
                    />
                    {' ' + kategori}
                  </label>
                </div>
              ))}
            </Box>

            {/* Tampilkan total */}
            <Box style={{ marginBottom: 50, textAlign: 'left', width: '100%' }}>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Total Biaya: Rp {total.toLocaleString('id-ID')}
              </Typography>
            </Box>

            {/* Input lain-lain */}
            <TextField
              label="Nama Lengkap Dengan Gelar"
              variant="outlined"
              className={classes.input}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <TextField
              label="Asal Institusi"
              variant="outlined"
              className={classes.input}
              value={institution}
              onChange={(e) => setInstitution(e.target.value)}
              required
            />
            <TextField
              label="Nomor Handphone"
              variant="outlined"
              className={classes.input}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />

            {/* Tambahkan field NIK */}
            <TextField
              label="NIK"
              variant="outlined"
              className={classes.input}
              value={nik}
              onChange={(e) => setNik(e.target.value)}
              required
            />

            <TextField
              label="Provinsi"
              variant="outlined"
              className={classes.input}
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              required
            />
            <TextField
              label="Kota"
              variant="outlined"
              className={classes.input}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <TextField
              label="Email"
              variant="outlined"
              className={classes.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              label="Buat Password"
              type="password"
              variant="outlined"
              className={classes.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextField
              label="Ulangi Password"
              type="password"
              variant="outlined"
              className={classes.input}
              value={repassword}
              onChange={(e) => setRePassword(e.target.value)}
              required
            />

            {/* Tombol Submit */}
            <Button type="submit" variant="contained" className={classes.button}>
              {loading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                <Typography variant="h6">Register</Typography>
              )}
            </Button>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Registrasi;
