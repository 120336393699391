import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const CountdownSection = () => {
  const targetDate = new Date('2025-07-03T00:00:00');

  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <Grid item xs={3} key={interval}>
      <Paper elevation={3} sx={{ p: 2, textAlign: 'center', justifyContent: 'center', borderRadius: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {timeLeft[interval]}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
          {interval.charAt(0).toUpperCase() + interval.slice(1)}
        </Typography>
      </Paper>
    </Grid>
  ));

  return (
    <Box sx={{ py: 8, background: 'linear-gradient(135deg, #002147, #00B4D8)' }}>
    <Container>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Typography variant="h3" sx={{ fontWeight: '700', textAlign: 'center', mb: 5, color: 'white' }}>
            MaCPLAM Schedule
        </Typography>

        <Box
          component="img"
          src="https://macplam.com/assets/jadwal.png" // sesuaikan URL dengan lokasi gambar di hosting
          alt="Event Banner"
          sx={{
            width: '100%',
            borderRadius: 2,
            boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
            mb: 4,
          }}
        />

        <Grid container spacing={2} justifyContent="center">
          {timerComponents.length ? timerComponents : <Typography variant="h6">Event has started!</Typography>}
        </Grid>
      </motion.div>
    </Container>
    </Box>
  );
};

export default CountdownSection;