import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, useMediaQuery,Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from '../assets/logo.png';

const Navbar = ({ akun, setAkun }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [navLinks, setNavLinks] = useState([]); // ✅ State untuk menyimpan menu
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  // ✅ Fungsi untuk mengambil menu berdasarkan status login
  const fetchNavLinks = async () => {
    try {
      const endpoint = akun.nama ? 'navbar_logged_api.php' : 'navbar_api.php'; // ✅ Tentukan endpoint berdasarkan `akun.nama`
      const response = await axios.get(`https://macplam.com/api/${endpoint}`);
      setNavLinks(response.data);
    } catch (error) {
      console.error('Error fetching menu:', error);
    }
  };

  useEffect(() => {
    console.log('akun.nama berubah:', akun.nama); // ✅ Debug: Cek perubahan `akun.nama`
    fetchNavLinks(); // ✅ Ambil menu saat komponen dimuat atau status login berubah
  }, [akun.nama]); // ✅ Jalankan ulang saat `akun.nama` berubah

  useEffect(() => {
    console.log('navLinks diperbarui:', navLinks); // ✅ Debug: Cek perubahan `navLinks`
  }, [navLinks]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenuOpen = (e) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <AppBar
        position="fixed"
        sx={{
          background: isScrolled 
            ? 'rgba(38, 49, 69, 0.90)' 
            : 'rgba(38, 49, 69, 0.99)', 
          backdropFilter: 'blur(10px)', 
          transition: 'all 0.3s ease-in-out', 
          boxShadow: isScrolled ? '0px 4px 10px rgba(0,0,0,0.2)' : 'none', 
          padding: '10px 0',
        }}
      >
        <Toolbar>
          <RouterLink to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', flex: 1 }}>
            <img 
              src={logo} 
              alt="MaCPLAM Logo" 
              style={{ 
                height: '50px', 
                marginRight: '16px', 
                transition: 'opacity 0.3s ease-in-out', 
              }}
              onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
              onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
            />
            <Typography
              variant="h6"
              component="span"
              sx={{
                flexGrow: 1,
                textDecoration: 'none',
                color: 'white',
                fontWeight: 700,
                fontFamily: 'Poppins, sans-serif',
                letterSpacing: '1px',
                transition: 'color 0.3s ease-in-out',
                '&:hover': { color: '#3FA34D' }, 
              }}
            >
              MaCPLAM
            </Typography>
          </RouterLink>
          {isMobile ? (
            <>
              <IconButton edge="end" color="inherit" onClick={() => setMenuOpen(true)}>
                <MenuIcon sx={{ fontSize: '32px' }} />
              </IconButton>
              <Drawer
                anchor="right"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                sx={{ '& .MuiDrawer-paper': { width: '80%', background: 'rgba(0,0,0,0.9)', color: 'white' } }}
              >
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                  <IconButton onClick={() => setMenuOpen(false)}>
                    <CloseIcon sx={{ color: 'white', fontSize: '32px' }} />
                  </IconButton>
                </div>
                <List sx={{ textAlign: 'center', padding: '20px' }}>
                  {navLinks.map((link, index) => (
                    <ListItem 
                      button 
                      key={index} 
                      component={RouterLink} 
                      to={link.path} 
                      onClick={() => setMenuOpen(false)}
                      sx={{
                        fontSize: '20px',
                        fontWeight: 600,
                        color: location.pathname === link.path ? '#3FA34D' : 'white', 
                        textAlign: 'center',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': { color: '#3FA34D' },
                      }}
                    >
                      <ListItemText primary={link.title} />
                    </ListItem>
                  ))}
                                    {akun.nama && (
                    <ListItem 
                      button 
                      onClick={() => {
                        setAkun({ nama: null })
                        setMenuOpen(false);
                      }}
                      sx={{
                        fontSize: '20px',
                        fontWeight: 600,
                        color: '#FF6F00',
                        textAlign: 'center',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': { color: '#FF4500' },
                      }}
                    >
                      <ListItemText primary="Logout" />
                    </ListItem>
                  )}

                </List>
              </Drawer>
            </>
          ) : (
            <>
            {navLinks.map((link, index) => (
              <Button
                key={index}
                component={RouterLink}
                to={link.path}
                sx={{
                  color: location.pathname === link.path ? 'yellow' : 'white', 
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                  position: 'relative',
                  marginRight: '24px',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': { color: '#3FA34D' },
                  '&::after': {
                    content: '""',
                    display: 'block',
                    width: location.pathname === link.path ? '100%' : '0%', 
                    height: '3px',
                    backgroundColor: '#FF6F00',
                    transition: 'width 0.3s ease-in-out',
                    position: 'absolute',
                    bottom: '-5px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  },
                  '&:hover::after': {
                    width: '100%',
                  },
                }}
              >
                {link.title}
              </Button>
            ))}
            {akun.nama && (
                <Box sx={{ marginLeft: 'auto' }}> {/* ✅ Posisikan di sebelah kanan */}
                  <Button
                    onClick={() => setAkun({ nama: null })}
                    sx={{
                      color: '#FF6F00',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '16px',
                      fontWeight: 500,
                      position: 'relative',
                      marginRight: '24px',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': { 
                        color: '#FF4500',
                        '&::after': {
                          width: '100%',
                          backgroundColor: '#FF4500',
                        }
                      },
                      '&::after': {
                        content: '""',
                        display: 'block',
                        width: '0%',
                        height: '3px',
                        backgroundColor: '#FF6F00',
                        transition: 'width 0.3s ease-in-out',
                        position: 'absolute',
                        bottom: '-5px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                      },
                    }}
                  >
                    Logout
                  </Button>
                </Box>
              )}

            </>
          )}
        </Toolbar>
      </AppBar>
    </motion.div>
  );
};

export default Navbar;