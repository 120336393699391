import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 100,
  },
  paper: {
    width: '100%',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  stepper: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  stepLabel: {
    fontWeight: 'bold',
  },
  progressContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressStep: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#F5F5F5',
    marginRight: theme.spacing(2),
  },
  progressStepActive: {
    backgroundColor: '#008CBA',
    color: 'white',
  },
  progressStepNumber: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  progressStepLabel: {
    textAlign: 'center',
  },
  button: {
    display: 'flex',
  },
}));

function getSteps() {
  return ['Payment', 'Registration Complete'];
}

export default function UserProfile(props) {
  const classes = useStyles();
  const steps = getSteps();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [pesanan, setPesanan] = useState({ total: 0 });
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  // State baru untuk event & bank
  const [eventInfo, setEventInfo] = useState({});
  const [bankData, setBankData] = useState({});

  // 1. Inisialisasi AOS
  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  // 2. Pastikan user sudah login
  useEffect(() => {
    if (!props.akun.email) {
      navigate('/login');
    }
  }, [props.akun, navigate]);

  // 3. Ambil data pesanan user
  useEffect(() => {
    if (!props.akun.email) return;

    const loading = async () => {
      try {
        const response = await axios.get(`https://quinnstechnology.com/macplam/webservice.php`, {
          params: {
            fungsi: 'pesanan',
            email: props.akun.email,
          },
        });
        const data = response.data;
        if (data.terbayar === '2') {
          setActiveStep(1);
        }
        setPesanan(data);
      } catch (error) {
        alert(error);
      }
    };
    loading();
  }, [props.akun]);

  // 4. Ambil data event & bank
  useEffect(() => {
    async function fetchAdditionalData() {
      try {
        // event data
        const eventRes = await axios.get('https://macplam.com/webservice.php?fungsi=event');
        setEventInfo(eventRes.data); // misal => { event: "8th MaCPLAM", year: "2024" }

        // bank data
        const bankRes = await axios.get('https://macplam.com/webservice.php?fungsi=bank');
        setBankData(bankRes.data);   // misal => { bank: "BCA", norek: "1100156705", nama: "steve" }
      } catch (error) {
        console.error(error);
      }
    }
    fetchAdditionalData();
  }, []);

  // 5. Konfirmasi pembayaran
  const konfirmasi = async () => {
    if (loading2 || loading1) return;
    setLoading1(true);
    try {
      const response = await axios.get(`https://quinnstechnology.com/macplam/webservice.php`, {
        params: {
          fungsi: 'konfirmasi',
          email: props.akun.email,
        },
      });
      const data = response.data;
      setLoading1(false);
      if (typeof data === 'object') {
        props.setAkun(data);
        setActiveStep(1);
      } else {
        alert('Silahkan menunggu konfirmasi dari panitia (maximal 1x24 jam)');
      }
    } catch (error) {
      alert(error);
      setLoading1(false);
    }
  };

  // 6. Batalkan registrasi
  const batalkan = async () => {
    if (loading2 || loading1) return;
    if (window.confirm('Apakah yakin ingin membatalkan registrasi?')) {
      setLoading2(true);
      try {
        const response = await axios.get(`https://quinnstechnology.com/macplam/webservice.php`, {
          params: {
            fungsi: 'batalkan',
            iduser: props.akun.id,
            idpesanan: pesanan.id,
          },
        });
        setLoading2(false);
        alert('Registrasi telah berhasil dibatalkan');
        navigate('/registrasi');
      } catch (error) {
        alert(error);
        setLoading2(false);
      }
    }
  };

  return (
    props.akun.email && (
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container} data-aos="fade-right">
          <Typography variant="h5" gutterBottom>
            User Profile
          </Typography>

          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              {/* Kiri: Data user */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" gutterBottom style={{ display: 'flex', marginBottom: 20 }}>
                  Registration Details
                </Typography>

                <Typography variant="subtitle2">Name:</Typography>
                <Typography variant="h6">{props.akun.nama}</Typography>

                <Typography variant="subtitle2">Email:</Typography>
                <Typography variant="h6">{props.akun.email}</Typography>

                <Typography variant="subtitle2">Institution:</Typography>
                <Typography variant="h6">{props.akun.asal}</Typography>

                <Typography variant="subtitle2">Phone Number:</Typography>
                <Typography variant="h6">{props.akun.no_hp}</Typography>

                <Typography variant="subtitle2">NIK:</Typography>
                <Typography variant="h6">{props.akun.nik}</Typography>

                <Typography variant="subtitle2">Province:</Typography>
                <Typography variant="h6">{props.akun.provinsi}</Typography>

                <Typography variant="subtitle2">City:</Typography>
                <Typography variant="h6">{props.akun.kota}</Typography>

                <Typography variant="subtitle2">Profession:</Typography>
                <Typography variant="h6">{props.akun.profesi}</Typography>
              </Grid>

              {/* Kanan: Progress & Payment */}
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom style={{ display: 'flex', marginBottom: 20 }}>
                  Progress
                </Typography>

                <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel className={classes.stepLabel}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {/* Step 0: Payment */}
                {activeStep === 0 && (
                  <div>
                    <Typography variant="h6" className={classes.stepTitle}>
                      Payment
                    </Typography>
                    <Typography variant="subtitle1">
                      Anda telah memesan <b>{pesanan.paket}</b> {eventInfo.event}.
                      {/* Jika event mengembalikan year juga: {eventInfo.year} */}
                      {' '}Mohon menyelesaikan pembayaran untuk pesanan Anda:
                    </Typography>

                    <Typography variant="subtitle1">
                      <b>
                        Total: Rp. {parseInt(pesanan.total || 0).toLocaleString('id-ID')},-
                      </b>
                    </Typography>

                    {/* Data Bank dari bankData */}
                    <Typography variant="subtitle1">
                      <b>Bank:</b> {bankData.bank}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b>No Rek:</b> {bankData.norek}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b>Nama:</b> {bankData.nama}
                    </Typography>

                    {/* Tombol Konfirmasi & Batalkan */}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={{ margin: 10, marginTop: 20, flex: 1 }}
                        onClick={konfirmasi}
                      >
                        {loading1 ? (
                          <CircularProgress style={{ color: 'white' }} />
                        ) : (
                          <Typography variant="h6">KONFIRMASI PEMBAYARAN</Typography>
                        )}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        style={{ margin: 10, marginTop: 20, flex: 1 }}
                        onClick={batalkan}
                      >
                        {loading2 ? (
                          <CircularProgress style={{ color: 'white' }} />
                        ) : (
                          <Typography variant="h6">BATALKAN REGISTRASI</Typography>
                        )}
                      </Button>
                    </div>
                  </div>
                )}

                {/* Step 1: Registration Complete */}
                {activeStep === 1 && (
                  <div>
                    <Typography variant="h6" className={classes.stepTitle}>
                      Registrasi Berhasil
                    </Typography>
                    <Typography variant="subtitle1">
                      Anda telah terdaftar <b>{pesanan.paket}</b> {eventInfo.event}.
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    )
  );
}
