import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

import Navbar from './components/Navbar';
// import Footer from './components/Footer';
import Home from './pages/Home';
import Jadwal from './pages/Jadwal';
import Freepaper from './pages/Freepaper';
import Registrasi from './pages/Registrasi';
import RegistrasiSusul from './pages/RegistrasiSusul';
import Virtual from './pages/Virtual';
import Simposium from './pages/Simposium';
import Contact from './pages/Contact';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import Profile from './pages/Profile';
import Booth from './pages/Booth';
import Materi from './pages/Materi';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
    width: '100%',
  },

}));
function App() {
  const [akun, setAkun] = useState({});
  // const [akun, setAkun] = useState({"id":"4","nama":"dr. Steven Tiro, SpPK","profesi":"Dokter Spesialis","asal":"Siloam Ambon","no_hp":"081224489393","provinsi":"Maluku","kota":"Ambon","email":"steven.tiro@gmail.com","password":"satu","paket":"Simposium (ONLINE) & Workshop"});
  const classes = useStyles();

  return (
    <Router>
      <Navbar akun={akun} setAkun={setAkun}/>
      <Routes>
        <Route exact path="/" element={<Home />}  />
        <Route exact path="/jadwal" element={<Jadwal />}  />
        <Route exact path="/freepaper" element={<Freepaper />}  />
        <Route exact path="/registrasi" element={<Registrasi akun={akun} setAkun={setAkun} />}  />
        <Route exact path="/registrasisusul" element={<RegistrasiSusul akun={akun} setAkun={setAkun} />}  />
        <Route exact path="/virtual" element={<Virtual />}  />
        <Route exact path="/contact" element={<Contact />}  />
        <Route exact path="/login" element={<Login akun={akun} setAkun={setAkun}/>}  />
        <Route exact path="/simposium" element={<Simposium akun={akun} setAkun={setAkun}/>}  />
        <Route exact path="/forgetpassword" element={<ForgetPassword />}  />
        <Route exact path="/profile" element={<Profile akun={akun} setAkun={setAkun}/>}  />
        <Route exact path="/booth/:id" element={<Booth akun={akun} setAkun={setAkun}/>}  />
        <Route exact path="/materi" element={<Materi akun={akun} setAkun={setAkun}/>}  />
      </Routes>

      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Typography variant="body1">@2016 MaCPLAM. All Rights Reserved.</Typography>
        </Container>
      </footer>
    </Router>
  );
}

export default App;
