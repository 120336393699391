import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import Carousel from 'react-material-ui-carousel';

const VenueSection = ({ config }) => {
  const [venueData, setVenueData] = useState({});

  useEffect(() => {
    fetch('https://macplam.com/config/venue.json')
      .then((response) => response.json())
      .then((data) => setVenueData(data.venue));
  }, []);

  return (
    <Box
      sx={{
        py: 8,
        backgroundImage: `url(${venueData.backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.6)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      <Container sx={{ position: 'relative', zIndex: 1 }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 'bold', color: 'white', textAlign: 'center', mb: 4 }}
        >
          {venueData.hotelName}
        </Typography>

        <Carousel autoPlay={true} interval={2500} navButtonsAlwaysVisible>
          {venueData.roomImages && venueData.roomImages.map((image, idx) => (
            <Paper key={idx} elevation={4} sx={{ overflow: 'hidden' }}>
              <Box
                component="img"
                src={image}
                alt={`Kamar${idx + 1}`}
                sx={{ width: '100%', height: { xs: '220px', md: '400px' }, objectFit: 'cover' }}
              />
            </Paper>
          ))}
        </Carousel>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Box
            component="img"
            src={venueData.priceImage}
            alt="Pricing"
            sx={{
              width: { xs: '100%', md: '80%' },
              borderRadius: 2,
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default VenueSection;