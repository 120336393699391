import React, {useEffect, useState} from 'react';
import { Container, Typography, Button, Box, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import CountdownSection from '../components/CountdownSection';
import PaketSection from '../components/PaketSection';
import WisataSection from '../components/WisataSection';
import VenueSection from '../components/VenueSection';

// Animasi Overlay Bergerak dari Kiri ke Kanan
const overlayVariants = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 1, ease: "easeOut" } },
};

// Animasi teks masuk dari bawah ke atas
const textVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut", delay: 0.5 } },
};

const HomePage = () => {
  const theme = useTheme();
  const [content, setContent] = useState({
    "title": "",
    "year": "",
    "subtitle": "",
    "date": "",
    "location": "",
    "button_text": "",
    "button_link": "",
    "background": ""
  });
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    fetch('https://macplam.com/config/content.json') // ✅ Pastikan path sesuai dengan lokasi hosting JSON
      .then(response => response.json())
      .then(data => setContent(data))
      .catch(error => console.error('Error fetching content:', error));
  }, []);


  return (
    <>
      <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100vh',
        color: '#fff',
        textAlign: 'left',
        backgroundImage: `url(https://macplam.com/assets/background.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Overlay Miring dengan Responsif */}
      <motion.div
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: isMediumScreen ? '100%' : '65%', // ✅ Layar md: overlay penuh
          height: '100%',
          background: 'rgba(0, 77, 115, 0.85)',
          clipPath: isMediumScreen
            ? 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)' // ✅ Full jika md
            : 'polygon(0 0, 100% 0, 60% 100%, 0% 100%)', // ✅ Normal untuk layar besar
          zIndex: 1,
        }}
      />

      {/* Konten dalam overlay dengan responsif */}
      <Container
        component={motion.div}
        variants={textVariants}
        initial="hidden"
        animate="visible"
        sx={{
          position: 'relative',
          zIndex: 2,
          width: isMediumScreen ? '90%' : '50%', // ✅ Layar besar lebih sempit agar lebih kiri
          textAlign: 'left', // ✅ Pastikan tetap rata kiri
          ml: isMediumScreen ? 2 : { xs: 2, md: 5, lg: 4 }, // ✅ Lebih rapat ke kiri di layar besar
          pl: isMediumScreen ? 0 : { xs: 2, md: 3, lg: 2 }, // ✅ Lebih kecil di layar besar
          paddingTop: isMediumScreen ? 5 : 10,
          paddingLeft: isMediumScreen ? 2 : 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>{content.title}</Typography>
        <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#FFA500' }}>{content.year}</Typography>
        <Typography variant="h5" sx={{ fontStyle: 'italic', mb: 2 }}>{content.subtitle}</Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{content.date}</Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>{content.location}</Typography>
        <Button
          variant="contained"
          size="large"
          component={motion.a}
          whileHover={{ scale: 1.05 }}
          href="registrasi"
          sx={{
            backgroundColor: '#F4C542',
            color: 'black',
            fontWeight: 'bold',
            fontSize: '18px',
            textTransform: 'none',
            padding: '12px 24px',
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: '8px',
            transition: 'all 0.3s ease-in-out',
            alignItems: 'center',
            gap: '8px',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
            },
          }}
        >
          Registrasi <span style={{ fontSize: 24, fontWeight: 'bold' }}>→</span>
        </Button>
      </Container>
    </Box>
    <CountdownSection/>
    <PaketSection/>
    <WisataSection/>
    <VenueSection/>


    <Box sx={{ bgcolor: '#007A9E', py: 6, paddingLeft: 10, paddingRight: 10, paddingTop: 15, paddingBottom: 15 }}>
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{ color: 'white', fontWeight: 'bold', mb: 1 }}
          >
            Harga EARLY BIRD!!!
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: 'white' }}
          >
            Manfaatkan harga khusus selama Early Bird
          </Typography>
        </Box>

        <Button
          variant="contained"
          size="large"
          component={motion.a}
          whileHover={{ scale: 1.05 }}
          href="registrasi"
          sx={{
            backgroundColor: '#F4C542',
            color: 'black',
            fontWeight: 'bold',
            fontSize: '18px',
            textTransform: 'none',
            padding: '12px 24px',
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: '8px',
            transition: 'all 0.3s ease-in-out',
            alignItems: 'center',
            gap: '8px',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
            },
          }}
        >
          Daftar Sekarang <span style={{ fontSize: 24, fontWeight: 'bold' }}>→</span>
        </Button>
      </Container>
      </motion.div>
    </Box>
    </>
  );
};

export default HomePage;
